.normalModeContainer {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 21px;
  cursor: pointer;
  font-size: 0.7rem;
  font-family: var(--font-roboto-400);
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.normalModeContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px solid var(--gray-border);
}

.normalModeContainer input:checked ~ .checkmark {
  background-color: var(--white);
  box-sizing: border-box;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.normalModeContainer input:checked ~ .checkmark:after {
  display: block;
}

.normalModeContainer .checkmark:after {
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--blue);
}

.normalModeContainer input {
  display: none;
}

#service {
  display: none;
}
