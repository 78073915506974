$screen-tablet: 1440px;
$screen-small-tablet: 1200px;

@mixin flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin horizontal-center{
  display: flex;
  justify-content: center;
}
@mixin vertical-center{
  display: flex;
  align-items: center;
}
@mixin row-direction{
  display: flex;
  flex-direction: row;
}
@mixin column-direction{
  display: flex;
  flex-direction: column;
}
@mixin row-space-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin borderOutline {
  border-radius: 6px;
  border: 2px solid #EFEFEF;
  background-color:#FFFFFF;
}

@mixin tablet {
  @media (max-width: #{calc($screen-tablet)}) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: #{calc($screen-small-tablet)}) {
    @content;
  }
}