.errorAlertContainer {
  position: fixed;
  margin: 10px;
  right: 0;
  top: -100px;
  width: 60%;
  height: 60px;
  border-radius: 7px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  animation: slideDown 0.6s forwards;
  span{    
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 500;
  }
  .errorText {
    margin-left: 30px;
  }
}
.crossIcon{
  cursor: pointer;
}
@keyframes slideDown {
  from {
    top: -100px;
  }
  to {
    top: 10px;
  }
}

.successAlert {
  background-color: #EDF7ED;
  color: #335835;
}

.errorAlert {
  background-color: rgb(253, 237, 237);
  color: rgb(95, 33, 32);
}
