@import '../../../mixins/mixins.scss';

.tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.tab {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  cursor: default;

  &:hover {
    border-bottom: 2px solid #5aa2dc;
    color: #5aa2dc;
  }
}

.isClickableTab {
  cursor: pointer;
}

.active {
  color: #5aa2dc;
  font-weight: 600;
  border-bottom: 2px solid #5aa2dc;
}

.timePickerWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 294px;
  position: relative;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

input[type='date'] {
  border: solid 1px #e0dede;
  background: #fff url('../../../assets/icons/timeInputIcon.svg');
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 97%;
}

input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}
