h2 {
  font-size: 20px;
}

.modal {
  height: 100%;
  width: 100%;
  background-color: var(--gray-for-modal);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
  position: fixed;
  inset: 0;
}


.modalContainer {
  width: 837px;
  border-radius: 20px;
  background-color: var(--white);
  box-shadow: 0 10px 10px var(--modal-shadow);
}

.formText {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 35px 50px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 20px;
  justify-content: space-between;
}

.loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  z-index: 999;
  top: 0;
  left: 0;
  p{
    font-family: var(--font-roboto-400);
    font-size: 0.8rem;
  }
}

.loadContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}