.modalOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  background: #ffffffb2;
  backdrop-filter: blur(10px);
  z-index: 9;
}

.darkThemeModalOverlay {
  background-color: rgb(0, 0, 0, 0.8);
  backdrop-filter: blur(30px);
}

.modalContainer {
  display: flex;
  width: 1100px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 100px;
  background-color: var(--white);
  border-radius: 20px;
  z-index: 99;
  box-shadow: 0px 0px 100px 0px #0000001a;
  max-height: 90dvh;
  overflow: hidden;

  @media (min-width: 3840px) {
    width: 1800px;
    border-radius: 40px;
  }
}

.darkModalContainer {
  background-color: rgb(0, 0, 0, 0.8);
  backdrop-filter: blur(30px);
}

.ticketsListContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  padding: 30px;
  border-radius: 20px;
  height: 70%;

  @media (min-width: 3840px) {
    gap: 60px;
    border-radius: 36px;
  }

  span {
    color: var(--white);
    font-family: var(--font-SFPro);
    font-style: normal;
    line-height: 73.99px;
    width: 100%;
    text-align: center;
  }
}

.ticketWrapper {
  display: grid;
  gap: 50px;
  width: 100%;
  min-height: 200px;
  box-sizing: border-box;
  padding: 20px;
  grid-template-columns: 31% 9% calc(60% - 100px);
  border-style: solid;
  border-width: 2px;
  border-radius: 35px;
  animation: changeBorderColor 1.5s infinite;

  @media (min-width: 3840px) {
    grid-template-columns: 31% 9% calc(60% - 200px);
    min-height: 350px;
    padding: 40px;
    border-width: 4px;
    gap: 100px;
  }
}

.arrowIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticketAlign {
  display: flex;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 100%;
}

.ticketIcon {
  animation: changeIcon 1.5s infinite;
}

.ticketName {
  padding: 10px 30px;
  min-width: 200px;
  border-radius: 20px;
  animation: changeIconColors linear 1.5s infinite;

  @media (min-width: 3840px) {
    padding: 20px 60px;
    min-width: 400px;
  }

  span {
    color: var(--white);
    font-size: 3.1rem;
    font-weight: 900;
    animation: changeTextSizes linear 1.5s infinite;

    @media (min-width: 3840px) {
      font-size: 5.6rem;
      line-height: 133px;
    }
  }
}

.ticketWindow {
  span {
    display: flex;
    width: 75%;
    color: var(--queueScreen-windowText);
    font-size: 3.1rem;
    font-weight: 700;
    text-transform: capitalize;
    animation: changeWindowTextColors linear 1.5s infinite;

    @media (min-width: 3840px) {
      font-size: 5.6rem;
      line-height: 133px;
    }
  }
}

.darkThemeText {
  span {
    animation: changeWindowDarkThemeTextColors linear 1.5s infinite;
  }
}

@keyframes changeIconColors {
  0% {
    background-color: var(--queueScreen-ticketBGgreen);
  }
  50% {
    background-color: var(--queueScreen-ticketBG);
  }
  100% {
    background-color: var(--queueScreen-ticketBGgreen);
  }
}

@keyframes changeBorderColor {
  0% {
    transform: scale(1);
    border-color: var(--queueScreen-ticketBG);
  }
  50% {
    transform: scale(1.1);
    border-color: var(--queueScreen-ticketBGgreen);
  }
  100% {
    transform: scale(1);
    border-color: var(--queueScreen-ticketBG);
  }
}

@keyframes changeWindowTextColors {
  0% {
    color: var(--queueScreen-windowText);
  }
  50% {
    color: var(--queueScreen-ticketBGgreen);
  }
  100% {
    color: var(--queueScreen-windowText);
  }
}

@keyframes changeWindowDarkThemeTextColors {
  0% {
    color: var(--white);
  }
  50% {
    color: var(--queueScreen-ticketBGgreen);
  }
  100% {
    color: var(--white);
  }
}

@keyframes changeIcon {
  0% {
    background: url('../../../../assets/icons/queueScreenArrowRightIcon.svg') center no-repeat;
  }
  50% {
    background: url('../../../../assets/icons/queueScreenArrowRightGreen.svg') center no-repeat;
  }
  100% {
    background: url('../../../../assets/icons/queueScreenArrowRightIcon.svg') center no-repeat;
  }
}
