.btnGroup {
  display: flex;
  align-items: center;
  width: 280px;
  justify-content: space-between;
}

.btn {
  width: 80px;
  height: 80px;
  box-sizing: border-box;
}

.btnGroup {
  button {
    font-family: Montserrat, sans-serif;
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 83px;
    letter-spacing: 0;
    text-align: center;
  }
}

.blueBtnBg {
  background-color: var(--blue-terminal);
}

.whiteBtnBg {
  background-color: var(--white);
}

.blackBtnBg {
  background-color: var(--black-terminal);
}

//blue theme

.blueTheme [data-color="blue"] {
  border: 1px solid var(--black-border);
}

.blueTheme [data-color="white"] {
  border: 1px solid var(--black-border);
}

.blueTheme [data-color="black"] {
  border: 1px solid var(--white);
  color: var(--white)
}

//white theme

.whiteTheme [data-color="blue"] {
  border: none;
}

.whiteTheme [data-color="white"] {
  border: 1px solid var(--black-border);
}

.whiteTheme [data-color="black"] {
  border: none;
  color: var(--white);
}

//black theme

.blackTheme [data-color="blue"] {
  border: none;
  color: var(--black-terminal);
}

.blackTheme [data-color="white"] {
  border: none;
  color: var(--black-terminal);
}

.blackTheme [data-color="black"] {
  border: 1px solid var(--white);
  color: var(--white)
}