.sectionForm {
  box-sizing: border-box;
}

.sectionContent {
  display: flex;
  flex-direction: row;
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  z-index: 999;
  top: 0;
  left: 0;
  p{
    font-family: var(--font-roboto-400);
    font-size: 0.8rem;
  }
}

.loadContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.radioContainer {
  margin-top: 31px;
  width: 156px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.radioContainer label {
  font-family: var(--font-roboto-400);
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
}

.radio {
  width: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameRu, .nameEn, .nameBe {
  width: 356px;
  height: 52px;
  box-sizing: border-box;
}

.nameRu {
  margin-top: 42px;
}

.searchContainer {
  width: 356px;
  height: 315px;
}

.searchContainer::placeholder {
  text-indent: 0;
}

.nameEn, .searchContainer {
  margin-top: 29px;
}

.nameBe{
  margin-top: 89px;
}

.serviceItem {
  height: 44px;
  border-bottom: 1px solid var(--gray-border);
  padding: 9px 8px 0 9px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-radius: 10px;
    box-shadow: 0 5px 5px var(--admin-modal-add-hover);
  }
}

.serviceItem, .addedServiceItem, .serviceDropDownItem {
  span {
    width: 337px;
    text-overflow: ellipsis;
    font-family: var(--font-roboto-400);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
  }
}

.addedServiceItem {
  cursor: pointer;

  &:hover {
    border-radius: 10px;
    box-shadow: 0 5px 5px var(--admin-modal-delete-hover);
  }
}

.rightPart {
  width: 100%;
  margin-left: 20px;
}

.timeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 19px;
}

.startTime, .endTime {
  position: relative;
}

.timeError {
  font-family: var(--font-inter);
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  color: var(--statusFalse);
  padding-left: 12px;
  margin: 0;
  width: 100%;
}

.right_time {
  margin-left: 20px;
}

.left_time, .start_time {
  width: 169px;
  height: 41px;
}

.chaptersDropdown {
  background-color: white;
  margin-top: 29px;
}

.addedServices {
  width: 356px;
  height: 315px;
  margin-top: 29px;
}

.addedServiceItem {
  height: 44px;
  border-bottom: 1px solid #E0DEDE;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonsContainer {
  width: 358px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.crossIcon {
  cursor: pointer;
}

.crossIcon {
  img {
    vertical-align: text-top;
  }
}

@media (max-height: 850px) {
  .searchContainer, .addedServices {
    height: 150px;
  }
}