.headerContainer {
  display: flex;
  height: 60px;
  padding: 10px 40px;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;

  @media (min-width: 3840px) {
    border-radius: 30px;
    height: 120px;
    padding: 35px 50px;
  }
}

.darkThemeHeaderContainer {
  background-color: rgb(0, 0, 0, 0.7);
  backdrop-filter: blur(30px);
}

.dateTimeContainer {
  display: flex;
  gap: 100px;
  flex-direction: row;
  align-items: center;
}

.dateTimeContainer span {
  font-family: var(--font-SFPro);
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;
  color: var(--black-dark);

  @media (min-width: 3840px) {
    font-size: 2.6rem;
    line-height: 62px;
  }
}

.darkThemeText span {
  color: var(--white);
}

.timeSectionComponent {
  display: flex;
  align-items: center;
  gap: 15px;

  @media (min-width: 3840px) {
    gap: 30px;
  }
}
