.serviceForm {
  box-sizing: border-box;
}

.serviceContent {
  display: flex;
  flex-direction: row;
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  z-index: 999;
  top: 0;
  left: 0;
  p{
    font-family: var(--font-roboto-400);
    font-size: 0.8rem;
  }
}

.loadContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.firstRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 19px;
}

.radioContainer {
  width: 156px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.radioContainer label {
  font-family: var(--font-roboto-regular);
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
}

.radio {
  width: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.letterContainer {
  width: 169px;
  height: 41px;
  margin-left: 30px;

  input {
    text-align: center;
  }
}

.letter {
  text-align: center;
}

.nameRu, .nameEn, .nameBe {
  width: 356px;
  height: 52px;
  box-sizing: border-box;
}

.nameRu, .nameEn, .nameBe {
  margin-top: 29px;
}

.rightPart {
  width: 100%;
  margin-left: 20px;
}

.timeContainer {
  display: flex;
  flex-direction: row;
  margin-top: 19px;
  justify-content: space-between;
}

.startTime, .endTime {
  position: relative;
}

.timeError {
  font-family: var(--font-inter);
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  color: var(--statusFalse);
  padding-left: 12px;
  margin: 0;
  width: 100%;
}

.right_time {
  margin-left: 20px;
}

.name_be {
  margin-top: 29px;
}

.buttonsContainer {
  width: 358px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 101px;
}