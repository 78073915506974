  .tableContainer {
    margin-top: 13px;
    background-color: var(--white);
    height: 100%;
    border: 1px solid var(--gray-border);
    border-radius: 10px;
    overflow-y: scroll;
  }
  
  .table {
    font-family: var(--font-inter);
    font-size: 0.7rem;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }
  
  
  // Table header
  .thTitle {
    font-family: var(--font-inter);
    font-size: 0.7rem;
    font-weight: 400;
    letter-spacing: 0em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .headerFirstColumnContent {
    padding: 10px 0 12px 44px;
  }
  
  .headerContent {
    padding-left: 16px;
  }
  
  .headerContentCenter{
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .headerContentRight {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 16px;
  }
  
  //Table body
  
  .bodyFirstColumnContent {
    display: flex;
    padding: 21px 6px;
  }
  
  .firstColumnText {
    display: flex;
    align-items: center;
    padding-left: 8px;
    
  }
  
  .columnContentCenter {
    display: flex;
    justify-content: center;
    padding-left: 6px;
  }

  .columnContentRight {
    display: flex;
    justify-content: flex-start;
    padding-left: 16px;
  }
  
  
  .colunmContent {
    padding: 20px 5px 20px 16px;
  }
  
  .pointer {
    cursor: pointer;
  }
  
  th, tr {
    border-bottom: 1px solid var(--gray-border);
  }
  
  .activeRow {
    background: var(--light-blue);
    
  }
  
  .colorResizer {
    width: 1px;
    height: 20px;
    background-color: #292828;
  }
  