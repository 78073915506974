.normalHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 120px;
  white-space: nowrap;
  background: var(--terminal-headerBG);
  padding: 0 50px;
  position: relative;

  @media (max-width: 1080px) {
    height: 160px;
    padding: 20px 50px;
  }
}

.infoContainer {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 1080px) {
    flex-wrap: wrap;
    max-width: 70%;
    padding: 10px;
  }
}

.settingsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;

  @media (max-width: 1080px) {
    order: 1;
  }
}

.dateWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;

  // @media (max-width: 1080px) {
  //   order: 1;
  // }
}

.languagesContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 15px 30px;
  min-width: 320px;

  gap: 20px;
  border: none;
  border-radius: 50px;
  background-color: var(--terminal-headerLangDropDownBG);

  // @media (max-width: 1080px) {
  //   order: 1;
  // }

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    border: none;
    background: none;
    color: var(--terminal-headerText);
    font-family: var(--font-SFPro);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 21.48px;
  }
}

.languagesContainerActive {
  background: #0066ff;
  box-shadow: 0px 0px 5px 0px #00000033 inset;
}

.langDropdownBtn {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background: none;

  &:hover {
    background: #0066ff;
    box-shadow: 0px 0px 5px 0px #00000033 inset;
  }
}

.languagesDropdownList {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 10px;
  background-color: var(--terminal-headerLangDropDownBG);
  box-shadow: 0px 0px 15px 0px var(--terminal-headerLangDropShadowBG);
  padding: 10px;
  position: absolute;
  z-index: 99;
  left: 60%;
  top: 90%;
}

.logoDateContainer {
  display: flex;
  align-items: center;
}

.time,
.date {
  span {
    color: var(--terminal-headerText);
    font-family: var(--font-SFPro);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 38.19px;
  }
}

.date,
.time {
  display: flex;
  gap: 15px;
}

.poorVisionIcon {
  border: 0;
  background: var(--white);
  border-radius: 15px;

  // @media (max-width: 1080px) {
  //   order: 1;
  // }
}

.languageBtn {
  font-family: Montserrat, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  width: 70px;
  height: 64px;
  border-radius: 20px;
  margin: 0 10px;

  border: 2px solid var(--blue-terminal-btns);
  color: var(--blue-terminal-btns);
  background-color: var(--white);
  box-sizing: border-box;
}

.active {
  border: none;
  background: linear-gradient(#61c1e9, #2c6fb4);
  color: var(--white);
}

@media (max-width: 1080px) {
  .icon img {
    width: 60px;
    height: 60px;
  }
  .date {
    margin-left: 20px;
  }
  .poorVisionIcon {
    width: 82px;
  }
}

@media (max-width: 1476px) {
  .settingsContainer {
    gap: 30px;
  }
}
