.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100% - 82px);
}

.tableContainer {
  margin-top: 13px;
  background-color: var(--white);
  height: 100%;
  border: 1px solid var(--gray-border);
  border-radius: 10px;
  overflow-y: scroll;
}

.tableParkingSpace {
  font-family: var(--font-inter);
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

// Table header
.thTitle {
  font-family: var(--font-inter);
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerFirstColumnContent {
  padding: 10px 0 12px 44px;
}

.headerContent {
  padding-left: 16px;
}

.headerContentCenter {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contentCenter {
  display: flex;
  padding: 21px 6px;
}

.activeRow {
  background: var(--light-blue);

}

.colorResizer {
  width: 1px;
  height: 20px;
  background-color: #292828;
}

//Table body

.bodyFirstColumnContent {
  display: flex;
  padding: 21px 6px;
}

.firstColumnText {
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.columnContentCenter {
  display: flex;
  justify-content: center;
  padding-right: 6px;
}

.editMenuContainer {
  position: relative;
}
.actionMenuContainer{
  position: absolute;
  z-index: 999;
  top: 29px;
  left: 0.5rem;
}
.colunmContent {
  padding-left: 16px;
  padding-right: 5px;
}

.pointer {
  cursor: pointer;
}

th, tr {
  border-bottom: 1px solid var(--gray-border);
}

.modifierImage {
  cursor: pointer;
}

tr th:last-child .resizer,
tr th:last-child .colorResizer {
  display: none;
}

.tableParkingSpace tr th:last-child {
  width: calc(100% - 1003px) !important;
}

.tableParkingSpace tr th:last-child .headerContentCenter {
  display: block;
  margin-left: 8px;
}

.tableParkingSpace tbody tr td:last-child .columnContentCenter {
  display: block;
  margin-left: 35px;
}
