.text {
  font-family: 'Bebas', sans-serif;
}
.headerValidLicense {
  width: 100%;
  height: 87px;
  border-top: 1px solid var(--gray-border);
  border-bottom: 1px solid var(--gray-border);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
}

.headerInvalidLicense {
  width: 100%;
  height: 87px;
  border-top: 1px solid var(--gray-border);
  border-bottom: 1px solid var(--gray-border);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--invalidLicense);
}

.datetime{
  display: flex;
  align-items: center;
}
.datetime span{
  font-family: var(--font-inter);
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--gray-dark);
}
.time{
  margin-left: 32px;
}
.date{
  margin-left: 30px;
}
.admin{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 47px;
}
.admin span{
  font-family: var(--font-inter);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: var(--gray-dark);
  margin-right: 40px;
}
.adminLogo{
  cursor: pointer;
  img{
      width: 37px;
      height: 37px;
  }
}
