.poorVisionContainer {
  overflow: hidden;
  height: 100%;
}

.headerContainer {
  position: fixed;
  width: 100%;
}

.ticketsContainer {
  overflow-y: scroll;
  padding-top: 162px;
  height: 100dvh;

  @media (max-width: 1510px) {
    padding-top: 182px;
  }
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.78);
  z-index: 99999;
  top: 0;
  left: 0;
  p {
    font-family: var(--font-roboto-bold);
    font-size: 1.3rem;
  }
}

.loadTicketContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.choiceServiceContainer {
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  padding-right: 68px;
  padding-left: 68px;
  margin-top: 36px;
}
.withoutArr {
  padding-left: 30px;
}

.title {
  font-family: Montserrat, sans-serif;
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 117px;
  letter-spacing: 0;
  text-align: left;
  color: var(--black-terminal);
  margin-right: 20px;
}

.whiteTitle {
  color: var(--white);
}

.ticketList {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 88%;
  overflow-y: scroll;
  margin: 2px 68px;
}

.ticket {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 204px;
  border-radius: 20px;
  border: 5px solid black;
  margin: 30px 0;
  background: var(--white);
  font-family: Montserrat, sans-serif;
  font-size: 3.2rem;
  font-weight: 800;
  line-height: 78px;
  letter-spacing: 0.075em;
  text-align: center;
  color: black;
  box-sizing: border-box;
  padding: 0 81px;
  cursor: pointer;
  &:disabled {
    background: #e2e3e5;
  }
}

.smallText {
  @media (max-width: 1510px) {
    font-size: 2.6rem;
  }
}

.bgBlue {
  background-color: var(--blue-terminal);
}

.bgWhite {
  background-color: var(--white);
}

.bgBlack {
  background-color: var(--black-terminal);
}

.noBorders {
  border: none;
}
.buttonBack {
  width: 150px;
  height: 80px;
  background-color: transparent;
  border: none;
}
.backImg {
  width: 150px;
  height: 80px;
}
.blackArrow * {
  fill: var(--white);
}
@media (max-width: 1572px) {
  .title {
    font-size: 4rem;
  }
}
@media (max-width: 1410px) {
  .title {
    font-size: 3rem;
  }
}
