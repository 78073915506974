.dropdown {
  width: 100%;
  height: 100%;
  position: relative;
}

.controller {
  height: 52px;
  border: 1px solid var(--gray-border);
  border-radius: 6px 6px 6px 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.controller {
  span {
    font-family: var(--font-roboto-400);
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
  }
}

.open {
  border-radius: 6px 6px 0 0;
}

.services {
  position: absolute;
  top: -7px;
  left: 17px;
  font-family: var(--font-roboto-regular);
  font-size: 0.6rem;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: center;
  color: var(--gray-dark);
  background-color: var(--white);
  z-index: 99;
}

.menu {
  position: absolute;
  border: 1px solid var(--gray-border);
  border-top: none;
  border-radius: 0 0 6px 6px;
  width: 100%;
  max-height: 340px;
  padding: 0 10px 20px 10px;
  top: 100%;
  background-color: white;
  overflow-y: scroll;
  z-index: 99;
  box-shadow: 0 10px 10px var(--dropdown-hover);
}

.menu::-webkit-scrollbar {
  width: 2px;
}

.menu::-webkit-scrollbar-thumb {
  background-color: #aeadad;
}

.image {
  position: absolute;
  right: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image img {
  width: 14px;
  height: 8px;
}