@import '../../mixins/mixins.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100dvh - 60px);
}

.ticket {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100dvw - 836px);
}

.ticketContainer {
  flex: 1;
  padding: 26px 32px;

  @include small-tablet {
    padding: 10px;
  }
}