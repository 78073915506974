@import "../../../mixins/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
}

.contentHeader{
  height: 82px;
  background-color: white;
  @include row-space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--gray-border);
}

.headerGroup {
  margin-left: 16px;
  margin-top: 12px;
}
.title{
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  color: #5AA2DC;
  margin-bottom: 8px;
}
.btn_container{
  display: flex;
  align-items: center;
  margin-right: 16px;
}
