.userAreaWrapper {
  padding-left: 25px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.title {
  color: var(--blue);
  font-size: 1.2rem;
}

.subTitle {
  color: var(--blue);
  font-size: 1rem;
}

.subTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.normalModeContainer {
  font-family: var(--font-inter);
  font-size: 0.8rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  height: calc(100vh - 87px);
}


.category span {
  padding-right: 5px;
}

.linkToService {
  font-family: var(--font-bebas);
  font-size: 1rem;
  line-height: 20px;
  font-weight: 700;
  margin-top: 38px;
}
.infoIcon{
  cursor: pointer;
}