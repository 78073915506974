.modal {
  height: 100dvh;
  width: 100dvw;
  background-color: var(--gray-for-modal);
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modalContainer {
  border-radius: 20px;
  padding: 35px 50px;
  background-color: var(--light-blue);
  box-shadow: 0 10px 10px var(--modal-shadow);
  position: relative;
}

.lockIcon {
  width: 30px;
  height: 30px;
}

.lockedTip {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 5px;
  background-color: '#4287ee';
  color: red;
  top: 115px;
  left: 250px;
  z-index: 99;
  padding: 5px;
}

.selectContainer {
  margin: 30px 65px;
  cursor: pointer;
}
.title {
  color: #000;
  font-family: var(--font-inter);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  margin-top: 46px;
  display: flex;
  justify-content: center;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 60px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4287ee;
  border-radius: 6px;
  border-color: transparent;
  color: var(--white);
  text-align: center;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 80px;

  &:disabled {
    background: var(--disabled);
    border-color: none;
    cursor: default;
  }
}
