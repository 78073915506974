
textarea {
  resize: none;
  overflow: hidden;
}

.textareaContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  * {
    border: none;
    outline: none;
    font-family: var(--font-roboto-regular);
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
  }
}

.customTextfield {
  box-sizing: border-box;
  border: 1px solid var(--gray-border);
  border-radius: 6px;
  background: none;
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
}

.error {
  border-color: var(--errorInput);
}

.second {
  overflow-y: scroll;
}

.second::-webkit-scrollbar {
  width: 2px;
}

.second::-webkit-scrollbar-thumb {
  background-color: #aeadad;
}

.customTextFieldContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.customLabel {
  position: absolute;
  font-size: 0.6rem;
  top: -9px;
  left: 8px;
  background-color: white;
  padding: 0 4px;
  z-index: 9;
  color: #404040;
}

.errorText {
  font-family: var(--font-inter);
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  color: #FF3D3D;
  padding-left: 12px;
  width: 100%;
}
