.modal {
  height: calc(100% - 120px);
  width: 100%;
  background: var(--white);
  position: fixed;
  top: 120px;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modalContainer {
  width: 528px;
  height: 820px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 30px;

  span{
    color: var(--white);
    font-family: var(--font-SFPro);
    font-style: normal;
  }
}


.titleSection {
  display: flex;
  flex-direction: column;
  gap: 40px;

  span {
    color: #000000;
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 54.89px;
    text-align: center;
  }
}

.ticketSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  position: relative;
  background: linear-gradient(180deg, #0066FF 0%, #50BFF3 100%);
}

.ticketCropLine {
  display: flex;
  width: 100%;
  position: absolute;
  top: -1px;
  height: 11px;
  background: url('../../../../assets/icons/terminalCropLineWaveIcon.svg');
  background-position: center;
}

.ticketMainInfoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.title {
  font-size: 6.5rem;
  font-weight: 700;
  line-height: 155.14px;
  padding: 100px 100px 0 100px;
}

.subTitle {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 43px;
  text-align: center;
  padding: 0 100px;
}


.advertisementInfoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 75px;
  margin-top: 100px;
}

.dateAndTimeSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 30px;
  margin-top: 50px;
}

.dateAndTimeSeparateLine {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  left: 50%;
}