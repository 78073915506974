.category span {
    padding-right: 5px;
}

.statusTrue {
    color: var(--statusTrue)
}

.statusFalse {
    color: var(--statusFalse)
}