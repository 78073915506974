.menuContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 152px;
  color: var(--white);
  border-radius: 10px;
  background-color: transparent;
  z-index: 999;
  margin-bottom: 0;
}

.menuContainer li {
  height: 31px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--blue);
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
  }
}