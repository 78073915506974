@import '../../../mixins/mixins.scss';

.container {
  background: var(--light-blue);
  width: 100%;
  padding: 27px 33px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 10px;
  column-gap: 13px;
  @include tablet {
    padding: 27px 33px;
  }

  @include small-tablet {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    padding: 10px;
  }

  button {
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-color: transparent; 
    padding: 14px 9px;
    background: var(--blue-button);
    text-align: center;
    font-family: var(--font-inter);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--white);

    &:disabled {
      background: var(--disabled);
      border: 0;
      color: var(--white);
      cursor: default;
    }

    @include small-tablet {
      padding: 7px 3px;
    }
  }

  .redirectBtn {
    background: inherit;
    border: 2px solid var(--blue-button);
    color: var(--blue-button);
  }

  .nextBtn {
    grid-row: span 2;
    background-color: var(--blue-button);
    border-color: transparent;
    font-family: var(--font-inter);
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--white);
    @include tablet {
      padding: 44px 10px 37px;
    }
  }
}




