.modal {
  height: 100dvh;
  width: 100dvw;
  background: rgba(5, 5, 5, 0.7);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modalContainer {
  width: 190px;
  height: 190px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  position: relative;
}

.spinnerIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

