@import '../../../mixins/mixins.scss';

.agentQueueContainer {
  width: 418px;
  height: calc(100dvh - 60px);
  border: 1px solid var(--gray-border);
  font-family: var(--font-inter);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;

  @include tablet {
    width: 280px;
    line-height: 12px;
  }
}

.header {
  display: grid;
  grid-template-columns: 15.5% 20% auto;
  align-items: center;
  font-size: 0.75rem;
  border-bottom: 1px solid var(--gray-border);
  padding: 4px 12px;

  @include tablet {
    display: grid;
    grid-template-columns: 16% 23% auto;
    font-size: 0.6rem;
  } 
}

.headerTime {
  display: flex;
  justify-content: center;
}
