@font-face {
  font-weight: 400;
  font-family: "Roboto-400";
  src: url("../src/assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-weight: 500;
  font-family: "Roboto";
  src: url("../src/assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-weight: 400;
  font-family: "Inter";
  src: url("../src/assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-weight: 500;
  font-family: "Inter";
  src: url("../src/assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-weight: 700;
  font-family: "Inter";
  src: url("../src/assets/fonts/Inter-Bold.ttf");
}

@font-face {
  font-weight: 400;
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-weight: 500;
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-weight: 600;
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-weight: 800;
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: "Bebas";
  src: url("../src/assets/fonts/BebasNeueBold.ttf");
}

@font-face {
  font-family: "SFPro";
  src: url("../src/assets/fonts/SFProDisplay-Regular.ttf");
}


html, body {
  height: 100%;
  font-size: 20px;
}

:root {
  --gray-border: #E0DEDE;
  --gray-terminal-border: #EBEBEB;
  --white: #FFFFFF;
  --gray-for-modal: rgba(198, 198, 198, 0.3);
  --gray-dark: #404040;
  --black: #373737;
  --queueScreen-windowText: #000000;
  --queueScreen-ticketBG: #0066FF;
  --queueScreen-ticketBGgreen: #07D91D;
  --queueWeatherCardBG: #FDFDFD;
  --terminal-headerText: #000000;
  --terminal-headerBG: #FDFDFD;
  --terminal-headerLangDropDownBG: #FFFFFF;
  --terminal-headerLangDropShadowBG: #0000000D;
  --terminal-GreetingText: #000000;
  --black-terminal: #292828;
  --black-dark: #1F1B20;
  --black-border: #262626;
  --light-blue: #F4FCFF;
  --blue-terminal: #9dd1ff;
  --blue-terminal-btns: #5AB6E2;
  --blue-queue: #57A4DB;
  --blue-text: #154469;
  --blue-trminal-dark: #0B3B60;
  --blue: #5AA2DC;
  --blue-dark: #2C6FB4;
  --blue-button: #4287EE;
  --main-header: #F5FCFF;
  --main-header-color: #053772;
  --blue-ticker: #0066FF4D;
  --statusTrue: #17AA14;
  --statusFalse: #cc1b1b;
  --errorInput: #FF3D3D;
  --disabled: #E2E3E5;
  --disabled-input: #f2f2f2;
  --operators-reset-btn: #FD4141;
  --operators-disabled-btn: #E2E3E5;
  --invalidLicense: #F96C6C;
  --operators-gray: #373737;
  --operators-blue: #4287EE;
  --operators-input-border: #EFEFEF;
  --operators-queue-border: #EFECEC;
  --operators-control-panel-background: #F5FCFF;
  --opeartors-input-placeholder: #ABA4A4;
  --admin-modal-delete-hover: rgba(255, 2, 2, 0.3);
  --admin-modal-add-hover: rgba(59, 131, 255, 0.3);
  --dropdown-hover: rgba(0, 0, 0, 0.4);
  --modal-shadow: rgba(0, 0, 0, 0.4);
  --font-bebas: 'Bebas', sans-serif;
  --font-inter: 'Inter', sans-serif;
  --font-roboto-400: 'Roboto-400', sans-serif;
  --font-roboto-bold: 'Roboto', sans-serif;
  --font-SFPro: 'SFPro', sans-serif;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

::-webkit-scrollbar {
  width: 0;
}

.border-outline {
  border-radius: 6px;
  border: 2px solid #EFEFEF;
  background-color: #FFFFFF;
}

input, textarea {
  outline: none;
}

input::placeholder {
  color: #ABA4A4;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

li {
  list-style-type: none;
}

ul {
  padding-left: 0;
}

.checkbox {
  transform: scale(1.85);
  opacity: 0.9;
  cursor: pointer;
}

/* LEFT COLUMN */

.button-category {
  width: 84px;
  height: 32px;
  color: #373737;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.agent-left-column {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
}

/* CENTER COLUMN */
.big-button {
  width: 235px;
  height: 112px;
  border: 0;
  border-radius: 6px;
  background-color: #E2E3E5;
  color: #FFFFFF;
}

.small-button {
  width: 235px;
  height: 48px;
  border: 0;
  border-radius: 6px;
  background-color: #E2E3E5;
  color: #FFFFFF;
}

.control-panel {
  background-color: #F5FCFF;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 22px;
  grid-row-gap: 16px;
}

.btn-secondary {
  background: #E2E3E5;
  color: #FFFFFF;
}

.col-7 {
  max-width: 1070px;
}

.select {
  border-radius: 6px;
  border: 1px solid #D1CDCD;
  background-color: #FFFFFF;
  width: 256px;
  height: 40px;
}

textarea {
  resize: none;
  display: flex;
  width: 1016px;
  height: 57px;
  justify-content: center;
  align-items: center;
}


.select:focus {
  border-radius: 6px;
  border: 1px solid #D1CDCD;
  background-color: #FFFFFF;
  width: 256px;
  height: 40px;
  outline: none;
}

.button-form {
  width: 220px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #E2E3E5;
  border: 0;
  color: #FFFFFF;
}

.clear-form {
  border: 2px solid #FD4141;
  background: #FFFFFF;
  color: #FD4141;
}

.form-error {
  display: inline-block;
  visibility: hidden;
}


/* Login */


.pass {
  position: relative;
}

.icon-pass {
  position: absolute;
  right: 15px;
  top: 50px;
  transform: translateY(-50%);
  cursor: pointer;
}

.login .input {
  height: 100px;
  font-size: 32px;
  font-weight: 500;
}

.form-control.is-invalid {
  background-image: none;
}

.login-form {
  width: 568px;
}

.input::placeholder {
  color: #373737;
  font-family: Inter;
  font-size: 32px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.modal-button {
  width: 118px;
}

.redirect-modal-width {
  --bs-modal-width: 530px;
}

.modal-width {
  --bs-modal-width: 346px;
}

.modal-title {
  color: #373737;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.redirect-modal-title {
  color: #373737;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.ticket-box {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 204px;
  border: none;
  border-radius: 10px;
}


.poor-vision-ticket-container {
  display: grid;
  gap: 40px;
  margin: 30px 68px;
  text-align: center;
}


.border-white {
  border-color: #FFF;
}

.navbar-border {
  border-bottom: 7px solid #0B3B60;
}

.poor-vision-navbar-date {
  color: #292828;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  margin-left: 20px;
}


.white {
  background: #FFF;
  color: #292828;
}

.bg-buttun-blue {
  background-color: #4287EE;
  color: #FFFFFF;
}

.bg-blue {
  background: #9DD1FF;
}

.col-white {
  color: #FFFFFF;
}

.bg-black {
  background: #292828;
  color: #FFFFFF;
}

.poor-vision-button-group button {
  border: 1px solid #292828;
}

.poor-vision-button-group button:nth-child(odd) {
  height: 88px;
  width: 140px;
}

.poor-vision-button-group button:nth-child(even) {
  height: 88px;
  width: 114px;
  border: 0;
  border-top: 1px solid #292828;
  border-bottom: 1px solid #292828;

}

footer {
  text-align: center;
  background-color: #FFFFFF;
  white-space: nowrap;
}


.video-react .video-react-big-play-button {
  display: none;
}


.active-button {
  background: #4287EE;
  color: #FFFFFF
}

.disabled-button {
  background-color: #E2E3E5;
  color: #FFFFFF;
}


.border-active-car {
  border: 2px solid #4287EE;
}

/* Table */


.resizer {
  padding: 0 3px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.ltr {
  right: 0;
}


.resizer.isResizing {
  background: blue;
  opacity: 1;
}


// Импорты из модулей храним здесь:

@import './components/Main/Modals/ModalRedirection/ModalRedirection.module.scss';