.modal {
  height: 100%;
  width: 100%;
  background-color: var(--gray-for-modal);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modalContainer {
  width: 599px;
  height: 265px;
  background-color: var(--light-blue);
  position: relative;
  padding: 50px 50px 64px 50px;
}

.modalContent p {
  font-family: var(--font-inter);
  font-size: 32px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0;
  text-align: center;
  margin: 0;
}

.btnUnderstand {
  margin-top: 33px;
  width: 220px;
  height: 40px;
  background-color: var(--blue-button);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  cursor: pointer;
  border: none;
}

.btnUnderstand p {
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  color: var(--white)
}