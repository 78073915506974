.menuButton{
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background: none;
  margin-left: 5px;
  padding-left: 9px;
  border-radius: 10px;
  cursor: pointer;
}
.menuButton.active{
  border-radius: 10px;
  background-color: var(--blue);
  color: var(--white);
}
.menuButtonText{
  font-family: var(--font-inter);
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}
.menuButton h3{
  margin: 0;
}