@import '../../../../mixins/mixins.scss';

.container {
  overflow-y: scroll;
  height: calc(100dvh - 90px);
  padding-bottom: 20px;
  padding: 12px;
}

.row {
  display: grid;
  grid-template-columns: 15.5% 20% auto;
  align-items: center;
  min-height: 36px;
  background-color: var(--white);
  padding: 14px 20px;
  position: relative;
  margin-top: 5px;

  &:hover {
    border-radius: 10px;
    z-index: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
  }

  @include tablet {
    display: grid;
    grid-template-columns: 16% 23% auto;
    padding: 4px 12px ;

    .rowTicket { 
      font-size: 0.8rem;
    }
    
    .rowTime {
      text-align: center;
      font-size: 0.75rem;
    }
    
    .rowServiceName {   
      font-size: 0.6rem;
      min-height: 24px;
    }

    .bottomLine {
      left: 12px;
      right: 12px;
    }

    .crossOutLine{
      left: 12px;
      right: 12px;
    }
  }
}

.rowTicket {
  font-size: 1rem;
}

.rowTime {
  text-align: center;
  font-size: 0.8rem;
}

.rowServiceName {
  display: flex;
  align-items: center;
  min-height: 36px;
  font-size: 0.8rem;
}

.bottomLine {
  position: absolute;
  left: 12px;
  right: 28px;
  bottom: 0;
  height: 1px;
  border-bottom: 1px solid var(--gray-border);
}

.crossOutLine {
  position: absolute;
  left: 18px;
  right: 34px;
  top: 50%;
  height: 1px;
  border-bottom: 1px solid var(--black);
}

.activeTicket {
  border: 1px solid var(--operators-blue);
  border-radius: 10px;
}

.hover:hover {
    cursor: pointer;
}