.modal {
  height: 100%;
  width: 100%;
  background-color: var(--gray-for-modal);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
  position: fixed;
  inset: 0;
}

.unlimitedAgentContainer {
  width: 837px;
  border-radius: 20px;
  background-color: var(--white);
  position: relative;
  padding: 35px 50px;
  box-shadow: 0 10px 10px var(--modal-shadow);
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  z-index: 999;
  top: 0;
  left: 0;
  p {
    font-family: var(--font-roboto-400);
    font-size: 0.8rem;
  }
}

.loadContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.radioContainer {
  margin-top: 24px;
  width: 407px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    font-family: var(--font-roboto-bold);
    font-size: 12px;
  }
}

.radioContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  width: 262px;
  justify-content: space-between;
  align-items: baseline;
}

.radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.radioContainer p {
  margin: 0;
}

.addContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 23px;
}

.searchItemContainer {
  width: 356px;
  height: 455px;
}

.addedItemContainer {
  width: 356px;
  height: 455px;
}

.serviceItem {
  height: 44px;
  border-bottom: 1px solid var(--gray-border);
  padding-left: 9px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: var(--font-roboto-400);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;

  &:hover {
    border-radius: 10px;
    box-shadow: 0 5px 5px var(--admin-modal-add-hover);
  }
}

.addedServiceItem {
  height: 44px;
  border-bottom: 1px solid #e0dede;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--font-roboto-400);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;

  &:hover {
    border-radius: 10px;
    box-shadow: 0 5px 5px var(--admin-modal-delete-hover);
  }
}

.buttonsContainer {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
}

.mainBtnsContainer {
  display: flex;
  gap: 20px;
}

@media (max-height: 850px) {
  .unlimitedAgentContainer {
    height: 550px;
  }
  .searchItemContainer,
  .addedItemContainer {
    height: 360px;
  }
}
