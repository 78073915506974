.modal {
  height: 100dvh;
  width: 100dvw;
  background-color: var(--gray-for-modal);
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modalContainer {
  background-color: var(--light-blue);
  border-radius: 20px;
  padding: 35px 50px;
  box-shadow: 0 10px 10px var(--modal-shadow);
}

.title {
  color: #373737;
  font-family: var(--font-inter);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.btn {
  text-align: center;
  font-family: var(--font-inter);
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 14px 40px;
  border-radius: 6px;

  &:hover {
    border-radius: 6px !important;
  }
}

.btnCansel {
  color: #FD4141;
  background: var(--white);
  border: 1px solid var(--operators-reset-btn);
  margin-right: 30px;
}

.btnConfirm {
  color: var(--white);
  background: var(--blue-button);
  padding: 14px 45px;
  border-color: transparent; 
}