.container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 82px);
}

.normalModeContainer {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.tableContainer {
  box-sizing: border-box;
  margin-top: 12px;
  background-color: var(--white);
  height: 100%;
  border: 1px solid var(--gray-border);
  border-radius: 10px;
  overflow-y: scroll;
}

.table {
  padding: 100px;
  font-family: var(--font-inter);
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

thead {
  height: 48px;
}

.thTitle {
  font-family: var(--font-inter);
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerFirstColumnContent {
  padding: 10px 0 12px 44px;
}

.headerContent {
  padding-left: 16px;
}

.headerContentCenter {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.colorResizer {
  width: 1px;
  height: 20px;
  background-color: #292828;
}

//Table body

.bodyFirstColumnContent {
  display: flex;
  padding: 21px 6px;
  align-items: center;
}

.firstColumnText {
  display: flex;
  align-items: center;
  padding-left: 8px;

}

.columnContentCenter {
  display: flex;
  justify-content: center;
  text-align: center;
}

.cellIsActive {
  display: flex;
  justify-content: center;
}

.colunmContent {
  padding-left: 16px;
  padding-right: 5px;
}

.expandedBtn {
  border: 0;
  background: inherit;
  width: 24px;
  height: 24px;
  cursor: pointer;
  box-shadow: none !important;
}

tr th {
  font-family: var(--font-inter);
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}


.iconSize {
  width: 24px;
  height: 24px;
  padding: 0;
}

.rotate {
  transform: rotate(180deg);
}


th, tr {
  border-bottom: 1px solid #E0DEDE;
}

.activeRow {
  background: #F4FCFF;
}

.editMenuContainer {
  position: relative;
}

.actonMenuContainer {
  position: absolute;
  z-index: 999;
  top: 29px;
  left: 0.5rem;
}

.modifierImage {
  cursor: pointer;
}

tr th:last-child .resizer,
tr th:last-child .colorResizer {
  display: none;
}

