.searchTFcontainer {
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray-border);
  border-radius: 6px;
}

.controller {
  position: relative;
  height: 36px;
  border-bottom: 1px solid var(--gray-border);
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.searchInput {
  border: none;
  text-indent: 0;
  resize: none;
  overflow: hidden;
  width: 100%;
  font-size: 14px;
}

.labelContainer {
  position: absolute;
  top: -7px;
  left: 17px;
  font-family: var(--font-roboto-regular);
  font-size: 0.6rem;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: center;
  color: var(--gray-dark);
  background-color: var(--white);
  padding: 0 4px;
}


.searchInput::placeholder {
  font-family: var(--font-roboto-400);
  //font-size: 0.6rem;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  color: #E0DEDE;
  //color: red;
  text-indent: 0 !important; /* Убираем отступ */
}

.crossImage {
  cursor: pointer;
  width: 24px;
  height: 24px;
  z-index: 99;
  margin-right: 6px;
}

.crossImage img {
  vertical-align: baseline;
}

.itemsContainer {
  height: calc(100% - 36px);
  padding: 0 10px 5px 9px;
  overflow-y: scroll;
}

.itemsContainer::-webkit-scrollbar {
  width: 2px;
}

.itemsContainer::-webkit-scrollbar-thumb {
  background-color: #aeadad;
}