.modal {
  height: 100%;
  width: 100%;
  background-color: var(--gray-for-modal);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
  position: fixed;
  inset: 0;
}

.modalContainer {
  width: 837px;
  border-radius: 20px;
  background-color: var(--white);
  position: relative;
  padding: 35px 50px;
  box-shadow: 0 10px 10px var(--modal-shadow);
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  z-index: 999;
  top: 0;
  left: 0;
  p {
    font-family: var(--font-roboto-400);
    font-size: 0.8rem;
  }
}

.loadContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.workpaceName {
  margin-top: 23px;
  height: 40px;
}

.passwordContainer {
  margin-top: 25px;
  width: 284px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.numberContainer {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
}

.accessLevel > div > div {
  width: 300px;
  height: 40px;
  box-sizing: border-box;
}

.accessLevelWarning {
  border: 1px solid red;
  border-radius: 6px;
}

.serviceItem {
  font-style: var(--font-roboto-400);
  font-size: 0.6rem;
  font-weight: 400;
}

.officeNumber {
  width: 115px;
  height: 40px;
  margin-left: 20px;
  & textarea {
    text-align: center;
  }
}

.windowNumber {
  width: 95px;
  height: 40px;
  margin-left: 20px;
  & textarea {
    text-align: center;
  }
}

.windowIP {
  width: 169px;
  height: 40px;
  margin-left: 20px;
  & textarea {
    text-align: center;
  }
}

.addEmployeesContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
}

.addEmployees {
  width: 356px;
}

.serviceItem,
.addedServiceItem {
  height: 45px;
  border-bottom: 1px solid var(--gray-border);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: var(--font-roboto-400);
  cursor: pointer;
}

.addedServiceItem {
  &:hover {
    border-radius: 10px;
    box-shadow: 0 5px 5px var(--admin-modal-delete-hover);
  }
}

.serviceItem {
  padding-left: 9px;

  &:hover {
    border-radius: 10px;
    box-shadow: 0 5px 5px var(--admin-modal-add-hover);
  }
}

.removeEmployees {
  width: 356px;
  height: 315px;
}

.crossContainer {
  margin-right: 8px;
  cursor: pointer;
}

.buttonsContainer {
  width: 50%;
  margin-top: 20px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
}

.userList {
  font-family: var(--font-roboto-400);
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  border-bottom: 1px solid var(--gray-border);
  padding: 14px 8px;
  cursor: pointer;

  &:hover {
    border-radius: 10px;
    box-shadow: 0 5px 5px var(--admin-modal-add-hover);
  }
}

.cancel {
  font-family: var(--font-inter);
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  background-color: var(--white);
  border: 1px solid var(--blue);
  border-radius: 10px;
  color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 47px;
  text-transform: uppercase;
  cursor: pointer;
}

.btnSave {
  font-family: var(--font-inter);
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  background-color: var(--blue);
  border: 1px solid #5aa2dc;
  border-radius: 10px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 32px;
  min-width: 166px;
  text-transform: uppercase;
  margin-left: 20px;
  cursor: pointer;
}
