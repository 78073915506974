.queuePageContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  height: 100dvh;
  overflow: hidden;
}

.darkQueuePageContainer {
  background: #fff url('../../assets/images/queueDarkBG.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.footerContainer {
  background: var(--blue-ticker);
  border-radius: 15px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  @media (min-width: 3840px) {
    height: 150px;
    border-radius: 30px;
  }
}

.darkThemeFooterContainer {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  background-color: rgb(0, 0, 0, 0.7);
}

.footerText {
  display: flex;
  gap: 20px;
  width: 100%;
  color: var(--queueScreen-windowText);
  font-family: var(--font-SFPro);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 38.19px;
  animation: marqueeText 25s linear infinite;

  @media (min-width: 3840px) {
    font-size: 3.2rem;
    line-height: 76px;
  }
}

.darkThemeFooterText {
  color: var(--white);
}

.runningString {
  display: flex;
  align-items: center;
  gap: 20px;
}

@keyframes marqueeText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
