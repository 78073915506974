.modal {
  height: 100%;
  width: 100%;
  background-color: var(--gray-for-modal);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.modalRedirectionContainer {
  width: 530px;
  border-radius: 20px;
  background-color: var(--white);
  padding: 35px 50px;
  position: relative;
  box-shadow: 0 10px 10px var(--modal-shadow);
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  z-index: 99999;
  top: 0;
  left: 0;
  p{
    font-family: var(--font-roboto-400);
    font-size: 0.8rem;
  }
}

.loadContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  p {
    color: var(--blue-button);
    font-size: 20px;
  }
}

.title {
  text-align: center;
}

.employeesContainer {
  margin-top: 18px;
}

.selectLabel {
  margin-bottom: 8px;
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
}

.servicesContainer {
  margin-top: 16px;
}

.disabled {
  color: #eae9e9;
}

.commentContainer {
  margin-top: 16px;
}

.commentContainer {
  textarea {
    width: 100%;
    height: 262px;
    border: 1px solid var(--black);
  }
}

.btnsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  margin-left: auto;
  justify-content: flex-end;
  gap: 30px;
}

.btnCancel, .btnRedirect {
  width: 114px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  border: none;
}

.btnCancel {
  background-color: transparent;
}

.btnRedirect {
  background-color: var(--blue-button);
  border-radius: 6px;
  color: var(--white);

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
}

.error {
  color: var(--toastify-color-error);
}

.arrow {
  cursor: pointer;
}

.arrow:hover path {
  stroke: var(--blue-button);
}

.custom-select__control {
  border-style: none !important;
  border-bottom: 1px solid #373737 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  min-height: 25px !important;
  max-height: 25px !important;
}

.custom-select__control--is-focused {
  box-shadow: none !important;
  border-bottom: 1px solid #4287EE !important;
}

.custom-select__input-container {
  padding-bottom: 4px !important;
}

.custom-select__value-container {
  padding: 0 !important;
  height: 25px !important;
  position: absolute !important;
}

.custom-select__indicators {
  position: absolute !important;
  top: -2px !important;
  right: 0 !important;
}

.custom-select__indicator {
  padding: 0 !important;
}
.custom-select__indicator-separator{
  display: none;
}
.custom-select__single-value {
  font-size: 16px !important;
  color: var(--black) !important;
  margin-bottom: 6px !important;
}

.custom-select__control--is-disabled {
  border-style: none !important;
  border-bottom: 1px solid #eae9e9 !important;
  background-color: transparent !important;
}