.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100% - 82px);
}

.tableContainer {
  margin-top: 12px;
  height: 100%;
  background-color: var(--white);
  border: 1px solid var(--gray-border);
  border-radius: 10px;
  overflow-y: scroll;
}

.table {
  font-family: var(--font-inter);
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}


// Table header
.thTitle {
  font-family: var(--font-inter);
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerAssignedServices {
  padding-left: 16px;
}

.headerEmployees {
  padding: 10px 0 12px 44px;
}

.contentCenter {
  display: flex;
  width: 100%;
  text-align: left;
  padding-left: 16px;
}

//Table body

.cellIsActive {
  display: flex;
  justify-content: center;
  padding-left: 6px;
}

.cellEmployees {
  display: flex;
  padding: 21px 6px;
}

.employees {
  padding-left: 8px;
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

th, tr {
  border-bottom: 1px solid var(--gray-border);
}

.activeRow {
  background: var(--light-blue);

}

.colorResizer {
  width: 1px;
  height: 20px;
  background-color: #292828;
}

.editMenuContainer {
  position: relative;
}

.actonMenuContainer {
  position: absolute;
  z-index: 999;
  top: 29px;
  left: 0.5rem;
}

.modifierImage {
  cursor: pointer;
}

tr th:last-child .resizer,
tr th:last-child .colorResizer {
  display: none;
}