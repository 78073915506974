.container {
  display: flex;
  height: 100vh;
  background-color: #f5fcff;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.title {
  color: var(--operators-blue);
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  justify-content: center;
  margin-bottom: 33px;
}

.formContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  position: absolute;
  border: 0;
  left: 60px;
  top: 60px;
}

.formInput {
  height: 100px;
  width: 100%;
  color: #373737;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  border: 2px solid #e8e6e6;
  padding-left: 25px;
  padding-right: 25px;
}

.formInput::placeholder {
  color: #373737;
  font-family: Inter;
  font-size: 32px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.footerWrapper {
  position: relative;
  top: 22%;
}

.footerTitle {
  color: var(--operators-blue);
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.error {
  position: relative;
  top: -10px;
  color: #373737;
  text-shadow: 2px 2px 4px rgba(236, 235, 235, 0.25);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.errorText {
  margin-left: 36px;
}

.loginBtn {
  height: 100px;
  width: 100%;
  color: var(--white);
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
  text-align: center;
  background-color: var(--operators-blue);
  border: none;
  border-radius: 10px;

  &:hover {
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }

  &:active {
    box-shadow: none;
    animation: pulse 0.1s ease-in-out;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.passwordWrapper {
  display: flex;
  position: relative;
}

.passwordShowIcon {
  position: absolute;
  right: 4%;
  top: 20%;
  cursor: pointer;
}

.authorizationFailMessage {
  color: var(--statusFalse);
  text-shadow: 2px 2px 4px rgba(236, 235, 235, 0.25);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-top: 16px;
}

.errorBorder {
  border: 2px solid var(--operators-reset-btn);
}
