.headerContainer {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  box-sizing: border-box;
  padding: 10px 68px 20px 68px;
  border-bottom: 2px solid black;
}

.poorVisionInfoHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  gap: 50px;

  @media (max-width: 1510px) {
    max-width: 60%;
    flex-wrap: wrap;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.settingsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.blackTechnoLogo {
  background-color: var(--black-terminal);
  color: var(--white);
}

.whiteTechnoLogo {
  background-color: var(--white);
  color: var(--black-terminal);
}

.blackTechnoLogo,
.whiteTechnoLogo {
  padding: 20px;
}

.dateTimeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.time,
.date {
  span {
    color: var(--black-dark);
    font-family: Montserrat, sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.date {
  margin-left: 132px;
}

#whiteDate {
  color: white;
}

.time {
  margin-left: 30px;
  margin-right: 15px;
}

.glassesIcon {
  margin: 0;
  padding: 0;
  border: none;
  height: 85px;

  @media (max-width: 1440px) {
    height: 70px;
  }
}

.headerLine {
  width: 100%;
  height: 7px;
  background: var(--blue-trminal-dark);
}

#whiteLine {
  background-color: var(--white);
}

.bgBlue {
  background-color: var(--blue-terminal);
}

.bgWhite {
  background-color: var(--white);
}

.bgBlack {
  background-color: var(--black-terminal);
}
@media (max-width: 1572px) {
  .date {
    margin-left: 40px;
  }
}
@media (max-width: 1410px) {
  .glassesIcon img {
    width: 70px;
    height: 70px;
  }
  .blackTechnoLogo,
  .whiteTechnoLogo {
    width: 150px;
    padding: 15px;
  }
  .blackSkyLogo,
  .whiteSkyLogo {
    width: 70px;
  }
  .date {
    margin-left: 30px;
  }
}
@media (max-width: 1218px) {
  .time,
  .date {
    span {
      font-size: 1.8rem;
    }
  }
}
