.normalModeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background: var(--white-gray);
}

.headerContainer {
  position: fixed;
  width: 100%;
}

.normalModeMain {
  height: calc(100% - 175px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--white);
  margin-top: 112px;
  margin-bottom: 63px;
  gap: 30px;
}

.chooseServiceText {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  @media (max-width: 1080px) {
    margin: 40px 0;
  }
}

.chooseServiceText {
  p {
    font-family: var(--font-SFPro);
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 54.89px;
    text-align: center;
    color: var(--terminal-GreetingText);
    margin: 0;
  }
}

.ticketList {
  height: calc(100dvh - 318px);
  display: grid;
  grid-template-rows: max-content;
  overflow-y: scroll;
  gap: 30px;
  padding: 100px 68px 0 68px;
  text-align: center;
}

.isBackBtnActive {
  margin-top: 20px;
}

.ticketListLargeIcons {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(max-content, 400px);
}

.ticketListSmallIcons {
  grid-template-columns: repeat(3, 1fr);
  row-gap: 100px;
  grid-auto-rows: minmax(max-content, 260px);
}

.ticketCardContainer {
  border: none;
  background: none;

  .ticketTextWrapper {
    display: flex;
    justify-content: center;
    min-width: 350px;
    width: 100%;
  }

  &:disabled .ticketCardSmall {
    background: #f2f2f2;

    span {
      color: #383838;
    }
  }

  &:disabled .ticketInnerCircleSmall {
    box-shadow: 0px 3px 4px 0px #00000040;
    background: url('../../../../../assets/icons/terminalTicketIconDisabled.svg') center no-repeat;
  }

  &:disabled .ticketOuterCircleSmall {
    border: 4px solid #f2f2f2;
  }
}

.ticketCardSmall {
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 10px;
  height: 260px;
  padding: 120px 40px 80px 40px;
  position: relative;
  background: linear-gradient(180deg, #0066ff 29.38%, #56c5f2 100%);

  &:active {
    background: #0752c3;
  }

  &:active .ticketInnerCircleSmall {
    box-shadow: 0px 3px 4px 0px #0752c3;
    background: url('../../../../../assets/icons/terminalTicketIconActiveSmall.svg') center
      no-repeat;
  }

  &:active .ticketOuterCircleSmall {
    border: 4px solid #0752c3;
  }

  span {
    color: var(--white);
    font-family: var(--font-SFPro);
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 36px;
    cursor: pointer;
  }
  &:disabled {
    background: #e2e3e5;
  }
}

.ticketCardLarge {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  height: 350px;
  padding: 210px 20px 20px 20px;
  position: relative;
  background: linear-gradient(180deg, #0066ff 0%, #56c5f2 179.57%);

  &:active .ticketInnerCircleLarge {
    width: 200px;
    height: 200px;
    background: url('../../../../../assets/icons/terminalTicketIconActiveLarge.svg') no-repeat;
    background-size: 100%;
    border: none;
    box-shadow: none;
  }

  span {
    color: var(--white);
    font-family: var(--font-SFPro);
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 42.96px;
    cursor: pointer;
  }
  &:disabled {
    background: #e2e3e5;
  }
}

.ticketOuterCircleSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid #0066ff;
  background-color: var(--white);
  position: absolute;
  top: -27%;
}

.ticketOuterCircleLarge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 280px;
  border-radius: 50%;
  border: 4px solid var(--white);
  background-color: var(--white);
  position: absolute;
  top: -27%;
}

.ticketInnerCircleSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  box-shadow: 0px 3px 4px 0px #5198f6;
  background: url('../../../../../assets/icons/terminalTicketIcon.svg') center no-repeat;
}

.ticketInnerCircleLarge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  box-shadow: 0px 3px 4px 0px #5198f6;
  background-color: #0752c3;
  background: url('../../../../../assets/icons/terminalTicketIcon.svg') center no-repeat;
  background-size: 37px;

  &:active {
    background: #0752c3;
    box-shadow: 0px 3px 4px 0px #0752c3;
  }
}

.normalModeFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 63px;
  background-color: var(--white);
  display: flex;
  align-items: center;
}

.tickerText {
  color: var(--black-dark);
  font-family: Montserrat, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 100%;
  animation: marqueeText 25s linear infinite;
}

.active {
  &:active {
    background: #0066ff;
    transform: translateY(4px);
  }
}

.buttonBack {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border: none;
  background-color: transparent;
  padding: 5px 30px 5px 5px;
  margin-top: 15px;
  border-radius: 50px;
  position: absolute;
  left: 40px;

  span {
    color: #0066ff;
    font-family: var(--font-SFPro);
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 26.25px;
  }

  &:active {
    background-color: #0066ff;

    .buttonBackArrow {
      background: url('../../../../../assets/icons/terminalBackArrowIconActive.svg') center
        no-repeat;
    }

    span {
      color: var(--white);
    }
  }
}

.buttonBackArrow {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url('../../../../../assets/icons/terminalBackArrowIcon.svg') center no-repeat;
}

.cropLineShow {
  display: flex;
  width: 100%;
  position: absolute;
  top: 97%;
  height: 8px;
  background: url('../../../../../assets/icons/terminalCropLineTriangleIcon.svg');
}

@keyframes marqueeText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 1476px) {
  .ticketList {
    grid-template-columns: 1fr 1fr;
  }
  .header {
    margin-bottom: 32px;
  }
}
