@import "../../../mixins/mixins.scss";

.tabs{
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;
}

.tab{
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid #5AA2DC;
    color: #5AA2DC;
  }
}

.active{
  color: #5AA2DC;
  font-weight: 600;
  border-bottom: 2px solid #5AA2DC;
}