.normal {
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid var(--blue);
  color: var(--blue);
  width: 169px;
  height: 38px;
  border-radius: 10px;
  font-family: var(--font-inter);
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
}
.active {
  background-color: var(--blue);
  border: none;
  color: white;
  z-index: 999;
}

.isDisabled {
  background-color: var(--disabled);
  border: none;
  color: white;
}

.delete {
  width: 118px;
}
