.modal {
  height: 100%;
  width: 100%;
  background-color: var(--gray-for-modal);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s;
}
.active {
  opacity: 1;
  pointer-events: all;
  z-index: 99;
  position: fixed;
  inset: 0;
}

h2 {
  font-family: var(--font-roboto-bold);
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  color: #404040;
  margin: 0;
}

.chapterContainer, .serviceContainer {
  width: 837px;
  padding: 35px 50px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 10px 10px var(--modal-shadow);
}

.chapterContainer {
  position: relative;
}

.serviceContainer {
  height: 368px;
  position: relative;
}

@media (max-height: 850px) {
  .chapterContainer {
    height: 550px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0; /* Начальное состояние анимации - прозрачность 0 */
  }
  to {
    opacity: 1; /* Конечное состояние анимации - прозрачность 1 */
  }
}