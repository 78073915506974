@import '../../../mixins/mixins.scss';

.container {
  font-family: var(--font-inter);
  color: var(--black);

  @include tablet {
    .ticketName {
      margin-bottom: 20px;
    }
    .timeContainer{
      margin-bottom: 20px;
    }
    .service {
      margin-bottom: 20px;
    }
    .comment {
      margin-bottom: 20px;
    }
    .titleInfo {
      padding-bottom: 0;
      font-size: 1rem;
    }
    .commentContainer {
      min-height: 30px;
      overflow-y: scroll;
    }
    .textInfo {
      font-size: 0.75rem;
    }
  }
}

.timeContainer {
  display: flex;
  margin-bottom: 30px;
}

.ticketName {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}

.request{
  margin-left: 33px;
}

.service {
  margin-bottom: 30px;
}

.comment {
  margin-bottom: 30px;
}

.commentContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  min-height: 60px;
  overflow-y: scroll;
}

.titleInfo {
  color: var(--black);
  font-family: inherit;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px;
}

.textInfo {
  color: var(--black);
  font-family: inherit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  width: 100%;
}

.timerInfo {
  display: flex;
  align-items: flex-start;
  color: var(--operators-blue);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: inline-block;
  margin-right: 10px;
}

.seconds {
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
