.weatherContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.hourForecast {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
    gap: 20px;
    border-radius: 15px;
    background: var(--queueWeatherCardBG);

    span {
        color: var(--black);
        font-family: var(--font-SFPro);
        font-style: normal;
      }

      .hours {
        font-size: 1rem;
        font-weight: 600;
        line-height: 23.87px;
      }

      .temperature {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 38.19px;
      }  
}