.headerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    white-space: nowrap;
    background: var(--main-header);
    padding: 0 32px;
    font-family: var(--font-inter);
    color: var(--main-header-color);
  }

  .timeContainer {
    display: flex;
    justify-content: center;
  }

  .date {
    color: inherit;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
  
  .time {
    color: inherit;
    text-align: center;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 30px; 
  }
  
  .username {
    color: inherit;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 18px ;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 999;

  }