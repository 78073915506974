.videoPlayerWrapper {
  border-radius: 30px;
  overflow: hidden;
  // aspect-ratio: 16 / 9;
  width: 100%;
  min-height: 641px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);

  video {
    object-fit: cover;
  }
}

.playBtn {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
  top: 42%;
  left: 44%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.imgIcon {
  position: absolute;
  left: -1px;
  top: 0;
}

.wave {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  animation: pulse 2.2s ease-in-out infinite;
}

.wave1 {
  animation-delay: 0;
}
.wave2 {
  animation-delay: 0.2s;
}
.wave3 {
  animation-delay: 0.4s;
}

.hideBtn {
  display: none;
}

.playBtnAnimation {
  outline: none;
  border: none;
  border-radius: 50%;
}

@keyframes pulse {
  0%,
  10% {
    box-shadow: 0 0 0 0 rgba(0, 102, 255, 0.7);
  }
  50% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
}
