.customLabel {
  position: absolute;
  font-size: 0.6rem;
  top: -9px;
  left: 8px;
  padding: 0 4px;
  z-index: 9;
  color: #404040;
  user-select: none;
  background-color: var(--white);
}

.timePickerContainer{
  width: 169px;
  height: 41px;
  border-radius: 6px;
  border: 1px solid var(--gray-border);
  display: flex;
  align-items: center;
  position: relative;
  transition: border-color 0.3s;
  &.active{
    border: 1px solid var(--blue);
  }
}

.selectedTime{
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeOptions{
  overflow: hidden; 
  height: 150px;
  overflow-y: scroll;
  position: absolute;
  top: 42px;
  z-index: 999;
  background-color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px; 
    height: 50px;
  } 
}

.optionTime:hover{
  background-color: #cfeaff;
}

.selectedTime, .optionTime{
  span{
    margin-left: 41px;
    font-family: var(--font-roboto-400);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    user-select: none;
  }
}

.optionTime{
  height: 30px;
  display: flex;
  align-items: center;
}

.clockIcon{
  margin-right: 15px;
  &:hover *{
    fill: var(--blue);
  }
}