.btnContainer {
  display: flex;
  flex-wrap: nowrap;
  height: 88px;
  margin-left: 50px;
  box-sizing: border-box;
}

.btnContainer {
  button {
    height: 100%;
    margin: 0;
    padding: 0;
    background: none;
    text-align: center;
    border: none;
  }
  button:not(:last-child) {
    border-right: 1px solid var(--black-terminal);
  }
}

.btnContainer {
  span {
    font-family: Montserrat, sans-serif;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0.075em;
  }
}

#blueTheme {
  border: 1px solid var(--black-terminal);
}

#whiteTheme {
  border: 1px solid var(--black-terminal);
}

#whiteTheme .active {
  background-color: var(--black-terminal);
}

#whiteTheme .active span {
  color: var(--white);
}

#blackTheme {
  border: 1px solid var(--white);
}

.ruBtn,
.enBtn {
  border-right: 1px solid var(--black-terminal);
}

#blackTheme .ruBtn,
#blackTheme .enBtn {
  border: 1px solid var(--white);
}

#blackTheme span {
  color: var(--white);
}

#blackTheme .active span {
  color: var(--black-terminal);
}

.ruBtn {
  width: 140px;
}

.byBtn {
  width: 140px;
}

.enBtn {
  width: 110px;
}

.btnContainer .active {
  background: var(--white);
  color: var(--black-terminal);
}

.btnContainer .activeWhite {
  background: var(--black-terminal);
  color: var(--white);
}
@media (max-width: 1220px) {
  .ruBtn {
    width: 100px;
  }

  .byBtn {
    width: 100px;
  }

  .enBtn {
    width: 70px;
  }
  .btnContainer {
    height: 80px;
    span {
      font-size: 1.8rem;
    }
  }
}
