.sidebar {
  width: 284px;
  height: 100vh;
  border: 1px solid var(--gray-border);
  box-sizing: border-box;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
}

.companyLogo {
  margin-top: 27px;
  margin-left: 29px;
}

.companyLogo img {
  width: 147px;
  height: 32px;
}

.sidebarHeader {
  margin-left: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 38px;
}

.sidebarHeaderText {
  margin-left: 16px;
}

.sidebarHeaderText p {
  font-family: var(--font-bebas);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: var(--blue);
  width: 179px;
  margin: 0;
}

.sidebarMenu { 
  margin-top: 10px;
  margin-left: 14px;
  height: 100%;
  overflow-y: scroll;
}

.sidebarMenu li {
  list-style-type: none;
  margin-bottom: 2px;
  width: 256px;
  height: 60px;
}

.btnAnalyticsCont {
  width: 256px;
  height: 60px;
}

#liAnalytics {
  height: auto;
}

.analyticsMenu {
  margin-top: 12px;
  margin-left: 13px;
}

.analyticsMenu li {
  font-family: var(--font-inter);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  width: auto;
  height: auto;
  cursor: pointer;
}

.item {
  display: inline-block;
}

.analyticsMenu li:not(:first-child) {
  margin-top: 16px;
}

#active {
  border-bottom: 2px solid var(--blue);
}

.sidebarFooter {
  position: sticky;
  margin-bottom: 32px;
  margin-left: 28px;
  margin-top: auto;
}

.sidebarFooter a {
  text-decoration: none;
  font-family: var(--font-inter);
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--blue);
  margin-left: 10px;
}

#mail, #globe {
  width: 24px;
  height: 24px;
}

.menuFooterMail, .menuFooterLink {
  display: flex;
  align-items: center;
}

.menuFooterLink {
  margin-top: 16px;
}

@media (max-width: 1440px) {
  .sidebarHeaderText {
    margin-left: 29px;
  }
  .sidebarHeader {
    margin-top: 36px;
  }
}

@media (max-height: 750px) {
  .sidebarMenu {
    max-height: 400px;
    overflow-y: scroll;
  }
  .sidebarMenu::-webkit-scrollbar {
    width: 2px;
  }
  .sidebarMenu::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .sidebarMenu::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
}


