.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 35px;
  height: 35px;

  & .path {
    stroke: var(--blue);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

.spinnerBtn {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 25px;
  height: 25px;

  & .path {
    stroke: var(--white);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}