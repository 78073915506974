.modal {
  height: 100%;
  width: 100%;
  background-color: var(--gray-for-modal);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
  position: fixed;
  inset: 0;
}

.modalLicence {
  width: 700px;
  border-radius: 20px;
  background-color: var(--white);
  box-shadow: 0 10px 10px var(--modal-shadow);
}

.licenseForm {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 35px 50px;
}

.addedServiceItem {
  height: 44px;
  border-bottom: 1px solid #e0dede;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.crossIcon {
  cursor: pointer;
}

.textContainer {
  margin-top: 24px;
}

.videoLoadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  margin-top: 10px;
}

.videoAdded {
  width: 356px;
  height: 120px;
}

.videoDownloading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
  }
}

.formatTip {
  display: flex;
  justify-content: flex-end;
  span {
    font-size: 0.8rem;
    font-style: italic;
  }
}

.buttonsContainer {
  width: 358px;
  height: 38px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  z-index: 999;
  top: 0;
  left: 0;
  p {
    font-family: var(--font-roboto-400);
    font-size: 0.8rem;
  }
}

.loadContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
