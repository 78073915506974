.skeleton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    
  }
  
  @keyframes gradient {
      0% {
          background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
  }
  
  .skeletonCell {
    width: 80%;
    height: 30%;
    border-radius: 15px;
    background: linear-gradient(90deg, var(--operators-disabled-btn), var(--white), var(--operators-disabled-btn), var(--white));
      background-size: 400% 400%;
      animation: gradient 3s ease infinite;
      transform: translate3d(0, 0, 0);
  }