.mainContainer {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;

    button {
        transition: box-shadow 0.3s;
    
        &:hover {
          border-radius: 6px;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
        }
    
        &:active {
          box-shadow: none;
          animation: pulse 0.1s ease-in-out;
        }
      }
    
      @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.9);
        }
        100% {
            transform: scale(1);
        }
    }
}