.modal {
  height: 100%;
  width: 100%;
  background-color: var(--gray-for-modal);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
  position: fixed;
  inset: 0;
}

.modalAccompaniment {
  width: 837px;
  height: 411px;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0 10px 10px var(--modal-shadow);
}

.accompanimentForm {
  margin: 26px 57px 26px 48px;
}

.textContainer {
  margin-top: 24px;
}

.textContainer span {
  font-family: var(--font-roboto-400);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}

.ulList {
  margin: 0;
}

.ulList li {
  list-style-type: disc;
  margin-left: 20px;
}

.servicesP {
  margin-bottom: 10px;
}

.informationP {
  margin-top: 10px;
  margin-bottom: 0px;
}

.btnContainer {
  width: 169px;
  margin-top: 24px;
  margin-left: auto;
}