.queuePanelContainer {
  height: 100%;
  width: calc(100% - 10px);
  gap: 10px;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 2fr auto;
  align-items: center;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  order: 1;
  height: 100%;
  width: 100%;
  cursor: pointer;
  video::-webkit-media-controls {
    opacity: 0;
  }
}

.videoBox {
  display: flex;
  width: 100%;
  height: 100%;
}

.titleContainer {
  display: flex;
  max-height: 270px;
  width: 100%;
  order: 3;
  border-radius: 30px;
  border: 1px solid #0000001a;
  padding: 20px 30px;

  @media (min-width: 1920px) {
    padding: 40px 30px;
  }

  @media (min-width: 3840px) {
    max-height: 560px;
    padding: 102px 60px;
  }
}

.darkThemeTitleContainer {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  background-color: rgb(0, 0, 0, 0.7);
  border-radius: 30px;
}

.isTitleActive {
  background: #fdfdfd;
}

.titleWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 42px;
  gap: 20px;

  .title {
    color: #000;
    font-family: var(--font-SFPro);
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;

    @media (min-width: 3840px) {
      font-size: 5.6rem;
      line-height: 108px;
    }
  }

  .subTitle {
    color: #626262;
    font-family: var(--font-SFPro);
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;

    @media (min-width: 3840px) {
      font-size: 3.6rem;
      line-height: 108px;
    }
  }

  .darkThemeText {
    color: var(--white);
  }

  @media (min-width: 3840px) {
    gap: 40px;
  }
}

.queueContainer {
  width: 100%;
  height: 100%;
  display: flex;
  grid-column: 2 / 2;
  grid-row: 1 / 3;
  order: 2;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  // @media (max-width: 1920px) {
  //   width: auto;
  // }
}

.darkQueueContainer {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  background-color: rgb(0, 0, 0, 0.7);
  border-radius: 30px;
}

.queueContainerHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 77px;
  gap: 15px;
  padding: 26px 40px;
  background-color: var(--queueScreen-ticketBG);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @media (min-width: 3840px) {
    height: 146px;
    padding: 40px 50px;
    border-top-left-radius: 26px;
    border-top-right-radius: 26px;
    gap: 30px;
  }
}

.darkQueueContainerHeader {
  background-color: transparent;
}

.queueHeaderTitlesWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.queueHeaderSeparateLine {
  position: relative;
  width: 100%;
  height: 1px;
}

.queueHeaderSeparateLine::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ffffff33;
}

.queueHeaderTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  span {
    color: var(--white);
    font-family: var(--font-SFPro);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 28.64px;

    @media (min-width: 3840px) {
      font-size: 2.6rem;
      line-height: 62px;
    }
  }
}

.queueContainerBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-height: 840px;
  padding-bottom: 15px;
  overflow: hidden;
  padding: 8px 20px;

  @media (min-width: 1920px) {
    gap: 15px;
    max-height: 840px;
  }

  @media (min-width: 3840px) {
    padding: 30px 40px;
    gap: 40px;
    max-height: 1690px;
  }
}

.nowrap {
  white-space: nowrap;
}

.title p {
  color: var(--white);
  font-family: var(--font-SFPro);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 51.47px;
  margin: 0;
}

.itemQueue {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 25% 35%;
  align-items: center;
  text-align: center;
  margin-left: 5px;

  @media (min-width: 1920px) {
    grid-template-columns: 40% 20% 40%;
  }
}

.itemQueueBlink {
  animation: blink 1s infinite;
}

.ticket {
  display: flex;
  height: 90px;
  width: 195px;
  box-sizing: border-box;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: var(--queueScreen-ticketBG);

  @media (min-width: 3840px) {
    padding: 20px 60px;
    width: 400px;
    height: 170px;
  }
}

.ticketBlinkBG {
  background-color: var(--queueScreen-ticketBGgreen);
}

.ticket span {
  color: var(--white);
  font-family: var(--font-SFPro);
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 900;
  line-height: 45.35px;
  width: 100%;

  @media (min-width: 3840px) {
    font-size: 5.6rem;
    line-height: 133px;
  }
}

.longTicketName span {
  font-size: 3.2rem;
}

.post {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-right: 30px;
}

.post {
  span {
    color: var(--queueScreen-windowText);
    text-align: center;
    font-family: var(--font-SFPro);
    // font-size: 1.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: 45.35px;
    text-transform: capitalize;

    @media (min-width: 3840px) {
      // font-size: 3.6rem;
      line-height: 88px;
    }
  }
}

.postDarkTheme {
  span {
    color: var(--white);
  }
}

.sizeLarge {
  font-size: 3rem;

  @media (min-width: 3840px) {
    font-size: 6rem;
  }
}

.sizeMiddle {
  font-size: 2.8rem;

  @media (min-width: 3840px) {
    font-size: 5.6rem;
  }
}

.sizeSmall {
  font-size: 2.4rem;
  margin-right: 15px;

  @media (min-width: 3840px) {
    font-size: 3.6rem;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes changeColors {
  0% {
    background-color: var(--queueScreen-ticketBG);
  }
  50% {
    background-color: var(--queueScreen-ticketBGgreen);
  }
  100% {
    background-color: var(--queueScreen-ticketBG);
  }
}

@media (max-width: 1600px) {
  .queuePanelContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: scroll;
  }

  .videoBox {
    justify-content: center;
    min-height: 300px;
  }

  .videoContainer {
    max-height: 640px;
    height: auto;
  }
}
