.appContainer{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    button {
      transition: box-shadow 0.3s;
  
      &:hover {
        border-radius: 10px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
      }
  
      &:active {
        box-shadow: none;
        animation: pulse 0.1s ease-in-out;
      }
    }

  
    @keyframes pulse {
      0% {
          transform: scale(1);
      }
      50% {
          transform: scale(0.9);
      }
      100% {
          transform: scale(1);
      }
    }
  }
  .rightPart{
    width: calc(100vw - 284px);
    background-color: var(--light-blue);
  }