.headerCalendarIcon {
  width: 21px;
  height: 20px;

  @media (min-width: 3840px) {
    width: 42px;
    height: 40px;
  }
}

.headerClockIcon {
  width: 20px;
  height: 20px;

  @media (min-width: 3840px) {
    width: 38px;
    height: 38px;
  }
}

.headerLogoIcon {
  width: 145px;
  height: 33px;

  @media (min-width: 3840px) {
    width: 290px;
    height: 67px;
  }
}

.queueTicketIcon {
  width: 26px;
  height: 33px;

  @media (min-width: 3840px) {
    width: 53px;
    height: 66px;
  }
}

.queueWindowIcon {
  width: 28px;
  height: 33px;

  @media (min-width: 3840px) {
    width: 56px;
    height: 66px;
  }
}

.queueArrowIcon {
  width: 40px;
  height: 35px;

  @media (min-width: 3840px) {
    width: 81px;
    height: 69px;
  }
}

.footerAsterixIcon {
  width: 40px;
  height: 45px;

  @media (min-width: 3840px) {
    width: 82px;
    height: 90px;
  }
}
