@import "../../../mixins/mixins.scss";

.contentHeader {
  height: 82px;
  background-color: white;
  @include row-space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--gray-border);
}

.headerGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 16px;
  padding-top: 12px;
  height: 100%;
}

.title {
  font-family: var(--font-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  color: #5AA2DC;
  margin-bottom: 8px;
}

.btnContainer {
  display: flex;
  align-items: center;
  margin-right: 16px;
}