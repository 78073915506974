.modal {
  height: 100%;
  width: 100%;
  background-color: var(--gray-for-modal);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
  position: fixed;
  inset: 0;
}

.modalContainer {
  width: 837px;
  border-radius: 20px;
  background-color: var(--white);
  padding: 35px 50px;
  position: relative;
  box-shadow: 0 10px 10px var(--modal-shadow);
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  z-index: 999;
  top: 0;
  left: 0;
  p{
    font-family: var(--font-roboto-400);
    font-size: 0.8rem;
  }
}

.loadContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.radioContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}


.radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.workpaceName {
  margin-top: 23px;
  height: 40px;
}

.windowNum {
  width: 100%;
  height: 40px;
}

.windowIP {
  width: 169px;
  height: 40px;
}

.passwordContainer {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addEmployeesCont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}

.addEmployees {
  width: 356px;
}

.serviceItem, .addedServiceItem {
  height: 44px;
  border-bottom: 1px solid var(--gray-border);
  display: flex;
  align-items: center;
}

.serviceItem {
  padding-left: 9px;
  cursor: pointer;
}

.removeEmployees {
  width: 356px;
  height: 315px;
}

.crossContainer {
  margin-right: 8px;
  cursor: pointer;
}

.buttonsContainer {
  width: 356px;
  margin-top: 20px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

