.modal {
  height: 100%;
  width: 100%;
  background-color: var(--gray-for-modal);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
  position: fixed;
  inset: 0;
}

.modalDelete {
  width: 346px;
  border-radius: 20px;
  background-color: var(--white);
  padding: 35px 50px;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 10px 10px var(--modal-shadow);

  p{
    text-align: center;
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
  }
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  z-index: 999;
  top: 0;
  left: 0;
  p{
    font-family: var(--font-roboto-400);
    font-size: 0.8rem;
  }
}

.loadContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.buttonsContainer{
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.normal{
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid var(--blue);
  color: var(--blue);
  width: 118px;
  height: 39px;
  border-radius: 10px;
  font-family: var(--font-inter);
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
}
.blueBtnYes{
  background-color: var(--blue);
  border: none;
  color:  white;
}